import React, { Component } from 'react';
import Reunion35 from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    const settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>Thirty Five Year Reunion - 1996</h1>
        <Reunion35 {...settings}>
          <div>
            <img src='/carousels/reunion_35yr/j000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j001.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j006.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j009.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j010.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j015.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j016.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j017.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j018.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j019.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j020.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j021.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j022.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j023.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j024.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j025.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_35yr/j026.jpg' alt='burroughs high' />
          </div>
        </Reunion35>
      </div>
    );
  }
}

