import React, { Component } from 'react';
import Reunion40 from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    const settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>Forty Year Reunion - 2001</h1>
        <Reunion40 {...settings}>
          <div>
            <img src='/carousels/reunion_40yr/k000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k001.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k006.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k009.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k010.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k015.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k016.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k017.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k018.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k019.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k020.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k021.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k022.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k023.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k024.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k025.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k026.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k027.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k028.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k029.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k030.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k031.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k032.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k033.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k034.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k035.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k036.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k037.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k038.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k039.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k040.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k041.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k042.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k043.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k044.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k045.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k046.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k047.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k048.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k049.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k050.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k051.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k052.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k053.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k054.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k055.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k056.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k057.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k058.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k059.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k060.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k061.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k062.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k063.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k064.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k065.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k066.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k067.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k068.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k069.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k070.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k071.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k072.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k073.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k074.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k075.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k076.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k077.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k078.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k079.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k080.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k081.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k082.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_40yr/k083.jpg' alt='burroughs high' />
          </div>
        </Reunion40>
      </div>
    );
  }
}

