import React, { Component } from 'react';
import SliderGradeSchool from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    let settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>Grade School - Class Photos</h1>
        <SliderGradeSchool {...settings}>
          <div>
            <img src='/carousels/grade_school/c000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c001.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c003a.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c006.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c009.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c010.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c014a.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/c015.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/SchoolGroves6th-54-55.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/SchoolRichmond6th-54-55.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/grade_school/viewigschool_200.jpg' alt='burroughs high' />
          </div>
        </SliderGradeSchool>
      </div>
    );
  }
}

