import React, { Component } from 'react';
import SliderMiddleYears from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    const settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>Our Middle Years - 1981-2011</h1>
        <SliderMiddleYears {...settings}>
          <div>
            <img src='/carousels/middle_years/m000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m003b.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m006.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m009.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m010.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m013a.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m015.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m016.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m017.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m018.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m019.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m020.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m021.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/middle_years/m022.jpg' alt='burroughs high' />
          </div>
        </SliderMiddleYears>
      </div>
    );
  }
}

