import React, { Component } from 'react';
import Reunion25pic from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    const settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>Twenty Five Year Reunion Picnic - 1986</h1>
        <Reunion25pic {...settings}>
          <div>
            <img src='/carousels/reunion_25yr/h000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh001.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh006.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh009.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh010.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh015.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh016.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh017.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh018.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh019.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh020.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh021.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh022.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh023.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh024.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh025.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh026.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh027.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh028.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh029.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh030.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh031.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh032.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh033.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh034.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh035.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh036.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh037.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh038.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh039.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh040.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh041.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh042.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh043.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh044.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh045.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh046.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh047.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh048.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh049.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh050.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh051.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh052.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh053.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh054.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh055.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh056.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh057.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh058.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh059.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh060.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh061.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh062.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh063.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh064.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yrpic/hh065.jpg' alt='burroughs high' />
          </div>
        </Reunion25pic>
      </div>
    );
  }
}

