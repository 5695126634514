import React, { Component } from 'react';
import Reunion30 from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    const settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>Thirty Year Reunion - 1991</h1>
        <Reunion30 {...settings}>
          <div>
            <img src='/carousels/reunion_30yr/i000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i001.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i006.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i009.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i010.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i015.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i016.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i017.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i018.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i019.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i020.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i021.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i022.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i023.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i024.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i025.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i026.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i027.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i028.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i029.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i030.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i031.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i032.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i033.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i034.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i035.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i036.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i037.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i038.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i039.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i040.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i041.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i042.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i043.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i044.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i045.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i046.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i047.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i048.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i049.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i050.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i051.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i052.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i053.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i054.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i055.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i056.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i057.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i058.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_30yr/i059.jpg' alt='burroughs high' />
          </div>
        </Reunion30>
      </div>
    );
  }
}

