import React, { Component } from 'react';
import SliderYoungAdults from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    const settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>Young Adults - 1961-19681</h1>
        <SliderYoungAdults {...settings}>
          <div>
            <img src='/carousels/young_adults/e000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e001.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e010.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e016.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e016b.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e017.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e018.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/young_adults/e019.jpg' alt='burroughs high' />
          </div>
        </SliderYoungAdults>
      </div>
    );
  }
}

