import React, { Component } from 'react';
import Reunion25 from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    const settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>Twenty Year Reunion - 1981</h1>
        <Reunion25 {...settings}>
          <div>
            <img src='/carousels/reunion_20yr/g000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g001.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g006.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g009.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g010.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g015.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g016.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g017.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g018.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g019.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g020.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g021.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g022.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g023.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g024.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g025.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g026.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g027.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g028.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g029.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g030.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g031.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g032.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g033.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g034.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g035.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g036.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g037.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g038.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g039.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g040.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g041.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g042.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g043.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g044.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g045.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g046.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g047.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g048.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g049.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g050.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g051.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g052.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g053.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g054.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g055.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_20yr/g056.jpg' alt='burroughs high' />
          </div>
        </Reunion25>
      </div>
    );
  }
}

