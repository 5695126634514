import React, { Component } from 'react';
import SliderHighSchool from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    const settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>High School Years - 1958-1961</h1>
        <SliderHighSchool {...settings}>
          <div>
            <img src='/carousels/high_school/d000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d001.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d009.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d010.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d015.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d016.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d017.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d018.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d019.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d020.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d021.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d021a.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d022.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d023.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d024.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d025.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d026.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d027.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d028.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d028a.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d029.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d030.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d031.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d032.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d033.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d034.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d035.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d036.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d037.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d038.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d039.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d040.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d041.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d042.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d043.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d044.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d045.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/high_school/d046.jpg' alt='burroughs high' />
          </div>
        </SliderHighSchool>
      </div>
    );
  }
}

