import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    let settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>The EARLY YEARS 1943-1958</h1>
        <Slider {...settings}>
          <div>
            <img src='/carousels/early_years/B000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B001a.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B001b.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B003a.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B005a.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B006.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B009.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B015.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B016.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B017.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/early_years/B018.jpg' alt='burroughs high' />
          </div>
        </Slider>
      </div>
    );
  }
}

