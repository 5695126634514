import React, { Component } from 'react';
import Reunion25 from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    const settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>Twenty Five Year Reunion - 1986</h1>
        <Reunion25 {...settings}>
          <div>
            <img src='/carousels/reunion_25yr/h000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h001.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h006.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h009.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h010.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h015.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h016.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h017.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h018.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h019.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h020.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h021.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h022.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h023.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h024.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h025.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h026.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h027.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h028.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h029.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h030.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h031.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h032.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h033.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h034.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h035.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h036.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h037.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h038.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h039.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h040.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h041.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h042.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h043.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h044.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h045.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h046.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h047.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h048.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h049.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h050.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h051.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h052.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h053.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h054.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h055.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h056.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h057.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_25yr/h058.jpg' alt='burroughs high' />
          </div>
        </Reunion25>
      </div>
    );
  }
}

