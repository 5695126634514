import React, { Component } from 'react';
import Reunion45 from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class AdaptiveHeight extends Component {
  render() {
    const settings = {
      className: '',
      dots: false,
      lazyLoad: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false
    };
    return (
      <div className="slider-vert-space">
        <h1>Forty Five Year Reunion - 2006</h1>
        <Reunion45 {...settings}>
          <div>
            <img src='/carousels/reunion_45yr/l000-min.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l001.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l002.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l003.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l004.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l005.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l006.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l007.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l008.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l009.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l010.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l011.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l012.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l013.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l014.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l015.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l016.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l017.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l018.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l019.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l020.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l021.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l022.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l023.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l024.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l025.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l026.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l027.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l028.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l029.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l030.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l031.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l032.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l033.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l034.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l035.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l036.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l037.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l038.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l039.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l040.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l041.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l042.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l043.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l044.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l045.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l046.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l047.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l048.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l049.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l050.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l051.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l052.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l053.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l054.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l055.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l056.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l057.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l058.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l059.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l060.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l061.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l062.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l063.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l064.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l065.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l066.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l068.jpg' alt='burroughs high' />
          </div>
          <div>
            <img src='/carousels/reunion_45yr/l069.jpg' alt='burroughs high' />
          </div>
        </Reunion45>
      </div>
    );
  }
}

